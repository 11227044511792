import React from "react";
import { RaterContext } from "../../RaterV3";
import { Grid, Typography } from "@material-ui/core";
import Product from "./ProductCard";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import _ from "lodash";
import ConfigureProduct from "./ConfigureProduct";
import ProductsCarousel from "./ProductCarousel";
import { RatingEngineContext } from "react-rating-engine";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
}));

export default function Products() {
  const classes = useStyles();
  const {
    products,
    setProducts,
    allProducts,
    setValue,
    allowed,
    loading,
    restrictedProducts,
    sendHSAQuote,
    setActiveProduct,
    setSendOptienhance,
    sendOptienhance,
    handleSendOptienhance,
  } = React.useContext(RaterContext);
  const { walking } = React.useContext(RatingEngineContext);
  const [productArr, setProductArr] = React.useState([]);
  const [otherProductArr, setOtherProductArr] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const [isConfiguring, setIsConfiguring] = React.useState(false);
  const [unavailable, setUnavailable] = React.useState([]);

  //Product state is used to control the ConfigureProduct component props so that it uses the product they selected.
  const [product, setProduct] = React.useState("");

  //State that controls whether the button to move forward is disabled/even shown at all. useEffect controls it to see if any products are filled out yet.
  const [disabled, setDisabled] = React.useState(true);

  //Probably not the best way to do this but just a quick solution to have product cards reflect the progress made on the product configuration.
  React.useEffect(() => {
    let arr = _.filter(
      Object.entries(products).map((entry) => {
        return {
          name: entry[0],
          data: entry[1],
          progress: entry[1].progress ? entry[1].progress : 0,
          saved: entry[1].saved ? entry[1].saved : [],
        };
      }),
      (x) => selected.indexOf(x.name) !== -1
    );
    let otherArr = _.filter(
      Object.entries(products).map((entry) => {
        return {
          name: entry[0],
          data: entry[1],
          progress: entry[1].progress ? entry[1].progress : 0,
          saved: entry[1].saved ? entry[1].saved : [],
        };
      }),
      (x) => selected.indexOf(x.name) === -1
    );
    setProductArr(arr);
    setOtherProductArr(otherArr);
  }, [products, selected]);

  //useEffect that filters out the products that aren't available based on available products versus what a user selected.
  React.useEffect(() => {
    let selected = _.filter(allProducts, (x) => x.selected).map((x) => x.type.toLowerCase());
    setSelected(selected);

    console.log("--- Product Availability Check ---");
    console.log("Selected products:", selected);
    console.log("All available products:", Object.keys(products));
    console.log("Restricted products:", restrictedProducts);

    let available = _.filter(
      Object.keys(products).map(
        (x) => x.toLowerCase(),
        (x) => restrictedProducts.map((x) => x.toLowerCase()).indexOf(x) === -1
      )
    );

    console.log("Available products after restrictions:", available);

    let notAvailable = [];
    selected.forEach((pro) => {
      if (available.indexOf(pro) === -1) {
        console.log(`Product "${pro}" is unavailable because:`, {
          isInProducts: Object.keys(products)
            .map((x) => x.toLowerCase())
            .includes(pro),
          isRestricted: restrictedProducts.map((x) => x.toLowerCase()).includes(pro),
          availableList: available,
        });
        notAvailable.push(pro);
      }
    });

    console.log("Final unavailable products:", notAvailable);
    setUnavailable(notAvailable);
  }, [products, allProducts, restrictedProducts]);

  //Controls the disabled function to see if any of the selected products are down, allowing users to move on and submit their quotes (or not).
  React.useEffect(() => {
    let bool = true;
    productArr.forEach((product) => {
      if (product.saved.length >= 1) bool = false;
    });
    otherProductArr.forEach((product) => {
      if (product.saved.length >= 1) bool = false;
    });
    setDisabled(bool);
  }, [productArr]);

  //Sets the configureProduct window to be configuring the selected product
  const configureProduct = (product) => {
    if (!walking && !loading) {
      setIsConfiguring(true);
      setProduct(product);
    }
  };

  //Removes a saved plan from the saved array of the corresponding product type.
  const deleteSaved = (index) => {
    let obj = _.cloneDeep(products);
    let arr = obj[product.name].saved;
    arr.splice(index, 1);
    obj[product.name].saved = arr;
    setProducts(obj);
  };

  // Add useEffect hooks for logging state changes
  React.useEffect(() => {
    console.log("productArr changed:", productArr);
  }, [productArr]);

  React.useEffect(() => {
    console.log("otherProductArr changed:", otherProductArr);
  }, [otherProductArr]);

  React.useEffect(() => {
    console.log("selected changed:", selected);
  }, [selected]);

  React.useEffect(() => {
    console.log("isConfiguring changed:", isConfiguring);
  }, [isConfiguring]);

  React.useEffect(() => {
    console.log("unavailable changed:", unavailable);
  }, [unavailable]);

  React.useEffect(() => {
    console.log("product changed:", product);
  }, [product]);

  React.useEffect(() => {
    console.log("disabled changed:", disabled);
  }, [disabled]);

  return (
    <div className={classes.paper}>
      <Grid container item xs={12} justify={"center"} alignItems={"flex-start"} alignContent={"flex-start"} spacing={3}>
        <Grid item xs={12}>
          <Typography variant={"h4"} style={{ fontWeight: "bold" }}>
            Customize your products
          </Typography>
          <Typography>
            Thank you for submitting the group information and Census! You can now quote the product (s) you initially
            selected, as well as the other available products listed below. Click on your selected Product to start
            quoting!
          </Typography>
        </Grid>
        {isConfiguring && !disabled && (
          <Grid item xs={10}>
            <Button
              fullWidth
              variant={"contained"}
              style={{ backgroundColor: "#0C2659" }}
              color={"primary"}
              disabled={disabled}
              onClick={() => setValue(5)}
            >
              Continue
            </Button>
          </Grid>
        )}
        <Grid
          item
          container
          xs={12}
          md={isConfiguring ? 4 : 12}
          justify={"center"}
          spacing={1}
          style={{ maxHeight: "70vh", overflow: "auto" }}
        >
          <Grid item xs={12}>
            <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
              Selected Products
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          {_.sortBy(productArr, (x) => x.name).map((productItem, index) => {
            if (allowed.indexOf(productItem.name.toLowerCase()) !== -1) {
              return (
                <Product
                  key={productItem.name}
                  configure={configureProduct}
                  active={productItem.name === product.name}
                  {...productItem}
                  index={index}
                  fullWidth={isConfiguring}
                  onClick={setActiveProduct(product.name)}
                />
              );
            } else return <></>;
          })}
          {productArr.length === 0 && (
            <>
              <Grid item xs={8}>
                <Typography align={"center"}>
                  None of the products you selected are available to you. Make sure you finish all previous steps
                  properly. If this problem persists, contact support.
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Button
                  fullWidth
                  variant={"contained"}
                  style={{ backgroundColor: "#0C2659" }}
                  color={"primary"}
                  onClick={() => setValue(0)}
                >
                  Click here to go back to the beginning
                </Button>
              </Grid>
            </>
          )}
          {otherProductArr.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography variant={"h6"} style={{ fontWeight: "bold" }}>
                  Other Available Products
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {_.sortBy(otherProductArr, (x) => x.name).map((productItem, index) => {
                if (allowed.indexOf(productItem.name.toLowerCase()) !== -1) {
                  return (
                    <Product
                      key={productItem.name}
                      configure={configureProduct}
                      active={productItem.name === product.name}
                      {...productItem}
                      index={index}
                      fullWidth={isConfiguring}
                      onClick={setActiveProduct(product.name)}
                    />
                  );
                } else return <React.Fragment key={productItem.name}></React.Fragment>;
              })}
            </>
          )}
          {unavailable.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography>Unavailable Products</Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {unavailable.map((product, index) => {
                const reason = restrictedProducts.map((x) => x.toLowerCase()).includes(product.toLowerCase())
                  ? "This product is currently restricted and cannot be quoted"
                  : !Object.keys(products)
                      .map((x) => x.toLowerCase())
                      .includes(product.toLowerCase())
                  ? "This product is not available in the current system"
                  : "This product is unavailable for your current selection";

                console.log(`Displaying unavailable product "${product}" with reason:`, reason);

                return (
                  <Product
                    key={product}
                    name={product}
                    unavailable
                    configure={() => console.log(`Product "${product}" is unavailable because: ${reason}`)}
                    fullWidth={isConfiguring}
                    unavailableReason={reason}
                  />
                );
              })}
            </>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          {React.useMemo(() => {
            return <>{isConfiguring && <ConfigureProduct {...product} />}</>;
          }, [product])}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        {products &&
          products[product.name] &&
          products[product.name].saved &&
          products[product.name].saved.length > 0 && (
            <>
              <Grid item container xs={12} justify={"center"} spacing={1}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={"h6"} align={"center"}>
                    Saved Plans
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <ProductsCarousel
                  sendHSAQuote={sendHSAQuote}
                  productArray={products[product.name].saved}
                  deleteSaved={deleteSaved}
                  editable
                  editFunction={configureProduct}
                  sendOptienhance={sendOptienhance}
                />
              </Grid>
            </>
          )}
        {isConfiguring && !disabled && (
          <Grid item xs={10}>
            <Button
              fullWidth
              variant={"contained"}
              style={{ backgroundColor: "#0C2659" }}
              color={"primary"}
              disabled={disabled}
              onClick={() => setValue(5)}
            >
              Continue
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
