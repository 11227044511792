import React, { useState, useEffect } from "react";
import _ from "lodash";
import Button from "../../Assets/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { RaterContext } from "../../RaterV3";
import { CSVReader } from "react-papaparse";
import uuidv4 from "uuid/v4";
//import { FirebaseContext } from "react-ugp-firebase";
import { CSVLink } from "react-csv";
import { CustomTextField } from "./CustomTextField";
import Collapse from "@material-ui/core/Collapse";
import CheckIcon from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import { FirebaseDB, FirebaseContext } from "react-ugp-firebase";
import { search, OptionsSelect } from "../../../utils/AsyncSelect";
import axios from "axios";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import date from "date-and-time";
import dateformat from "dateformat";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  prediction: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#5482D2",
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function RFP() {
  // ...
  const classes = useStyles();
  const {
    error,
    setLoading,
    setOpportunityId,
    setCensus,
    setZipCode,
    census,
    setSicCode,
    setGroupEffectiveDate,
    setError,
    setSuccess,
    setMessage,
    setRestricted,
    setHasOpportunity,
    validateCensus,
    state,
    setState,
    setGroupName,
    setValue,
    setAdminFees,
    webError,
    requiredLevel,
    setSelectedBroker,
    selectedBroker,
    discounts,
    setDiscounts,
  } = React.useContext(RaterContext);

  const [censusFile, setCensusFile] = useState({ file: "", name: "" });
  const [censusParsed, setCensusParsed] = useState(false);
  const [censusOption, setCensusOption] = useState("");
  const [name, setName] = useState({ value: "", error: false });

  const { user } = React.useContext(FirebaseContext);

  const sessionToken = React.useMemo(() => {
    return uuidv4();
  }, []);

  const censusOptions = [
    "Upload census in Optimed format",
    // "Upload census in any format",
  ];

  const genDates = () =>
    _.map(_.range(13), (index) => {
      const formatPattern = "MM/01/YYYY";
      return date.format(
        date.addMonths(date.parse(date.format(new Date(), formatPattern), formatPattern), index),
        formatPattern
      );
    });

  const exampleCensus = [
    ["name", "gender", "coverage", "dob"],
    ["John Smith", "M", "EE", "03/02/1957"],
    ["Becky Reed", "F", "EC", "10/20/1988"],
    ["Gilbert Ronahue", "M", "EF", "05/17/1990"],
    ["Rhonda Marty", "F", "EE", "07/01/1983"],
    ["Gina Flex", "F", "ES", "08/20/1977"],
    ["Bill Gilfoyle", "M", "EF", "01/14/1987"],
  ];

  const selectStyles = {
    menu: (styles) => ({ ...styles, zIndex: "1 !important" }),
  };

  const buttonRef = React.createRef();

  const { upload, restConfig } = React.useContext(FirebaseContext);
  //const { setLoading, setCensus, setError, setMessage, setSuccess, setValue, requiredLevel } = React.useContext(RaterContext);

  // ...
  const fileUpload = (evt, setFile) => {
    if (evt.target) {
      let file = evt.target.files[0];
      if (file && file.size / 1024 / 1024 > 3) {
        alert("The selected file is too big, please select a different file that's 3MB or less");
      } else {
        setFile({ file: file, name: evt.target.value ? evt.target.value : "" });
      }
    } else {
      setFile({ file: evt, name: evt.name ? evt.name : "" });
    }
  };

  function arrayToCSV(objArray) {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      return str;
    }, str);
  }

  /*Parses the data off of the csv file into our full census.*/
  const handleReadCSV = (data) => {
    let employees = data.map((row) => {
      return row.data;
    });

    const dobRanges = employees.map((employeeData) => {
      console.log("this is DOB");
      console.log(employeeData.dob);
      const dob = moment(employeeData.dob, "MM/DD/YYYY");
      const now = moment();

      const difference = now.diff(dob, "days");

      console.log("this is difference");
      console.log(difference);

      return difference;
    });

    //3652 === 10 years (Including leap years) from now() function.
    const dobCheck = (arr) => arr.filter((x) => x < 3652).length;

    //console.log(dobCheck(dobRanges));

    // ...

    if (dobCheck(dobRanges) === 0) {
      employees = _.map(employees, (x) => {
        return _.mapKeys(x, (value, key) => {
          return key.toLowerCase();
        });
      });
      employees = employees.map((employee) => {
        let dob = dateformat(employee.dob, "mm/dd/yyyy", true);
        let gender = employee.gender ? employee.gender.charAt(0) : undefined;
        return {
          name: employee.name,
          dob,
          coverage: employee.coverage,
          gender,
        };
      });

      employees = _.filter(employees, (x) => x.name && x.dob && x.coverage && x.gender);
      validateCensus(employees)
        .then((res) => {
          setLoading(true);
          setTimeout(() => {
            setCensusParsed(true);
            // console.log("this is census")
            // console.log(employees)
            setCensus(employees);
            let csvArray = arrayToCSV(employees);
            let blob = new Blob([csvArray], { type: "text/csv" });
            setCensusFile({
              file: new File([blob], `${name.value}Census${sessionToken}.csv`, {
                type: "text/csv",
              }),
              name: `${name.value}Census${sessionToken}.csv`,
            });
            setLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          setError(true);
          setLoading(false);
          setCensusParsed(false);
          setMessage(
            typeof err === "string"
              ? err
              : "Invalid census format. You must either fill out census manually or upload a file with the proper format."
          );
        });
    } else {
      setError(true);
      setLoading(false);
      setCensusParsed(false);
      setMessage("Your census contains an ineligible DOB for a member. Eligible members must be 10 years or older.");
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const openDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  React.useEffect(() => {
    setCensusFile({ file: "", name: "" });
  }, [censusOption]);

  React.useEffect(() => {
    const getDiscounts = async () => {
      const brokerRef = FirebaseDB.collection("discounts").doc(selectedBroker);
      await brokerRef
        .get()
        .then((doc) => {
          setDiscounts({ ...discounts, ...doc.data().discounts });
        })
        .catch((err) => {
          console.log("No discount found.", err);
        });
    };

    if (selectedBroker != undefined) {
      getDiscounts();
    }
  }, [selectedBroker]);

  //Called during the onKeyDown event for number fields to disallow the characters 'e', '-' and '+'
  const numberTest = (evt) => {
    var charCode = !evt.charCode ? evt.which : evt.charCode;
    if (charCode === 107 || charCode === 109 || charCode === 69) {
      evt.preventDefault();
      return false;
    }
  };

  // ...

  /*NOTE:
    When a user changes the type of census they wish to use, the censusFile variable is reset back to its original state.
  */
  React.useEffect(() => {
    setCensusFile({ file: "", name: "" });
  }, [censusOption]);

  return (
    <div>
      <Grid item xs={12} style={{ zIndex: 12 }}>
        <Typography>Upload Updated Census</Typography>
        <Tooltip
          title={
            "Optimed's census file type allows you to upload a file in our format and not have to reenter all of your census data."
          }
          placement={"top"}
        >
          <Select
            value={censusOption}
            placeholder={"Census File Type"}
            onChange={(e) => setCensusOption(e)}
            maxMenuHeight={150}
            options={censusOptions.map((x) => {
              return { label: x, value: x };
            })}
            styles={selectStyles}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={censusOptions.indexOf(censusOption.label) === 1}>
          <CustomTextField
            label={"Upload member level census. Include Name, DOB, Gender and Coverage"}
            type={"file"}
            onChange={(e) => fileUpload(e, setCensusFile)}
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={censusOptions.indexOf(censusOption.label) === 0}>
          <Grid item xs={12}>
            {censusParsed && (
              <Typography align={"center"} color={"primary"}>
                Census is Valid <CheckIcon />
              </Typography>
            )}
            {!error && (
              <CSVReader
                ref={buttonRef}
                onFileLoad={handleReadCSV}
                onError={handleOnError}
                noClick
                noDrag
                disabled={censusParsed}
                config={{ header: true }}
              >
                {({ file }) => (
                  <Button
                    disabled={censusParsed}
                    onClick={openDialog}
                    style={{
                      backgroundColor: "#0C2659",
                      color: "#FFFFFF",
                    }}
                    endIcon={censusParsed ? <CheckIcon /> : ""}
                  >
                    Click here to select a file in OptiMed format
                  </Button>
                )}
              </CSVReader>
            )}
            <Grid item xs={12}>
              <CSVLink filename={"ExampleCensus.csv"} className={classes.link} data={exampleCensus}>
                <Button
                  size="small"
                  disabled={censusParsed}
                  type={"button"}
                  variant={"text"}
                  fullWidth
                  color={"#0C2659"}
                  style={{
                    backgroundColor: "#0C2659",
                    color: "#FFFFFF",
                  }}
                >
                  Download example census format
                </Button>
              </CSVLink>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </div>
  );
}
